import {
    OPEN_TAKEOVER,
    CLOSE_TAKEOVER,
    UPDATE_HEIGHT,
} from './constants';

export function openTakeover() {
    return { type: OPEN_TAKEOVER };
}

export function closeTakeover() {
    return { type: CLOSE_TAKEOVER };
}

export function updateHeight(newHeight) {
    return {
        type: UPDATE_HEIGHT,
        payload: newHeight,
    };
}
