import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { VelocityComponent } from 'velocity-react';
import styles from './styles.scss';

import { GENERIC_TAKEOVER_DURATION } from './constants';

class GenericTakeover extends React.Component {
    constructor(props) {
        super(props);
        this.takeoverContainer = React.createRef();
    }
    render() {
        const {
            isOpen,
            children,
            className,
            containerHeight,
        } = this.props;

        const forwardTranslateAnimation = {
            translateX: (isOpen ? [0, '-100%'] : ['-100%', 0]),
        };

        const reverseTranslateAnimation = {
            translateX: (isOpen ? ['-100%', 0] : [0, '-100%']),
        };

        const currentHeight = _.get(this.takeoverContainer, 'current.clientHeight') || 0;
        const heightToRender = Math.max(currentHeight, containerHeight);

        return (
            <VelocityComponent
                animation={isOpen ? 'fadeIn' : 'fadeOut'}
                duration={GENERIC_TAKEOVER_DURATION}>
                <div
                    ref={this.takeoverContainer}
                    className={classNames(className, styles.takeover)}
                >
                    <div
                        className={styles.forwardSkew}
                        style={{
                            minWidth: `calc(100% + ${heightToRender}px)`,
                        }}
                    >
                        <VelocityComponent
                            animation={forwardTranslateAnimation}
                            duration={GENERIC_TAKEOVER_DURATION}>
                            <div className={styles.forwardTranslate}>
                                <div className={styles.reverseSkew}>
                                    <VelocityComponent
                                        animation={reverseTranslateAnimation}
                                        duration={GENERIC_TAKEOVER_DURATION}>
                                        <div className={styles.reverseTranslate}>
                                            <div className={styles.downsize}>
                                                {children}
                                            </div>
                                        </div>
                                    </VelocityComponent>
                                </div>
                            </div>
                        </VelocityComponent>
                    </div>
                </div>
            </VelocityComponent>
        );
    }
}

GenericTakeover.propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    containerHeight: PropTypes.number,
};

GenericTakeover.defaultProps = {
    isOpen: false,
    children: null,
};

export default GenericTakeover;
