import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Slider from 'react-slick';
import Asset from '../Asset/index.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import MaybeLink from '../MaybeLink.jsx';

import styles from './slider.scss';

function getValidButtonProps(props) {
    return _.omit(props, ['slideCount', 'currentSlide', 'homepageSlides']);
}

function PrevArrow(props) {
    const validProps = getValidButtonProps(props);
    return <button type="button" aria-label="Previous" {...validProps} />;
}

function NextArrow(props) {
    const validProps = getValidButtonProps(props);
    return <button type="button" aria-label="Next" {...validProps} />;
}

class Carousel extends React.Component {
    constructor(props) {
        super(props);

        this.sliderRef = React.createRef();

        this.state = {
            continueAutoplay: true,
            activeSlide: 0,
            carousel: 'hide',
            isClient: false, // Prevents issue with server side rendering when responsive option is used and slides to show differs
        };
    }

    componentDidMount() {
        this.setState({ isClient: true });
        window.addEventListener('scroll', this.onWindowScroll.bind(this)) //binding context allows us to use setState in event handler
        const arrows = document.getElementsByClassName('slick-arrow');
    }

    onWindowScroll() {
        const atTopOfPage = window.scrollY === 0;
        this.setState({ continueAutoplay: atTopOfPage });
    }

    onSlideMouseEnter(slideIndex) {
        this.setState({ activeSlide: slideIndex });
    }

    pause(ev) {
        this.sliderRef.current.slickPause();
    }

    render() {
        const {
            homepageSlides,
        } = this.props;

        const {
            activeSlide,
            isClient,
        } = this.state;

        if (!homepageSlides) {
            return null;
        }

        let slidesToShow = 3;

        if (homepageSlides.length === 2) {
            slidesToShow = 2;
        } else if (homepageSlides.length === 1) {
            slidesToShow = 1;
        }

        const settings = {
            dots: false,
            infinite: !!(homepageSlides.length >= 3),
            speed: 500,
            initialSlide: 0,
            slidesToShow,
            slidesToScroll: 1,
            autoplay: this.state.continueAutoplay,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            focusOnSelect: false,
            prevArrow: <PrevArrow {...this.props} />,
            nextArrow: <NextArrow {...this.props} />,
            onInit: () => {
                this.setState({ carousel: 'show' });
            },
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };

        return (
            <div onClick={ev => this.pause(ev)} onKeyDown={ev => this.pause(ev)} role="button" tabIndex="0" className={styles.slideContainer}>
                <div className={styles.logoShadow}>&nbsp;</div>
                <Slider {...settings} ref={this.sliderRef} className={this.state.carousel === 'show' ? 'u-visible' : 'u-visibility-hidden'} key={isClient ? 'client' : 'server'}>
                    {homepageSlides.map((slide, idx) => (
                        <MaybeLink
                            key={idx}
                            href={slide.slide_url}
                            fallbackTag="div"
                            className={classNames(styles.slideLink, { [styles.isActive]: activeSlide === idx })}
                            onMouseEnter={() => this.onSlideMouseEnter(idx)}
                        >
                            <Asset asset={slide.slide_asset} data-object-fit="cover" extraProps={{ className: styles.asset }} />
                            <div className={styles.slideContent}>
                                {slide.slide_category &&
                                    <div className={`${styles.slideType} type-callout-subtitle  white`}>
                                        {slide.slide_category}
                                    </div>
                                }

                                <ConditionalRender condition={slide.slide_title}>
                                    <div className="type-homepage-carousel white">
                                        {slide.slide_title}
                                    </div>
                                </ConditionalRender>

                                <ConditionalRender condition={slide.slide_url}>
                                    <div className={`${styles.readMorelink}  type-callout-subtitle  u-margin-top-med  white`}>
                                        {slide.read_more_override || 'Learn More'}
                                    </div>
                                </ConditionalRender>
                            </div>
                            <div className={styles.gradientOverlay} />
                            <div className={styles.bottomOverlay} />
                        </MaybeLink>
                    ))}
                </Slider>
            </div>
        );
    }
}

Carousel.propTypes = {
    homepageSlides: PropTypes.arrayOf(PropTypes.shape({
        slideAsset: PropTypes.shape(Asset.propTypes),
        slideCategory: PropTypes.string,
        slideTitle: PropTypes.string,
        slideUrl: PropTypes.string,
        readMoreOverride: PropTypes.string,
    })),
};

Carousel.defaultProps = {
    homepageSlides: [],
};

export default Carousel;
