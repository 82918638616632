import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import GenericTakeover from '../GenericTakeover';
import ConditionalRender from '../ConditionalRender';
import RawMarkup from '../RawMarkup';
import Logo from '../Nav/Logo';
import Asset from '../Asset';

import styles from './takeover.scss';

import {
    openTakeover,
    closeTakeover,
    updateHeight,
} from './actions';

import { statsTakeover_reduxKey } from './constants';

const mapStateToProps = state => _.get(state, statsTakeover_reduxKey) || {};

const mapDispatchToProps = dispatch => ({
    dispatchOpenTakeover: () => dispatch(openTakeover()),
    dispatchCloseTakeover: () => dispatch(closeTakeover()),
    dispatchUpdateHeight: newHeight => dispatch(updateHeight(newHeight)),
});

const THROTTLE_INTERVAL = 100;

function renderStat(statObj, statIdx) {
    const {
        stat,
        description,
    } = statObj;

    if (!stat && !description) {
        return null;
    }

    return (
        <React.Fragment>
            { statIdx > 0 ? <div className={styles.statDivider} /> : null }
            <div className={styles.gridStat}>
                <div className="type-takeover-stats">
                    {stat}
                </div>
                <div className="type-takeover-stats-description">
                    {description}
                </div>
            </div>
        </React.Fragment>
    );
}

class HomepageTakeover extends React.Component {
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();
        this.updateContentHeight = _.throttle(
            this.updateContentHeight.bind(this),
            THROTTLE_INTERVAL,
            { leading: true, trailing: true },
        );
        this.shouldOpen = this.shouldOpen.bind(this);
    }

    componentDidMount() {
        const {
            dispatchOpenTakeover,
        } = this.props;

        if (this.shouldOpen()) {
            dispatchOpenTakeover();
        }

        if (window.history.scrollRestoration) {
            window.history.scrollRestoration = 'manual';
        }

        this.heightUpdateInterval = setInterval(this.updateContentHeight, THROTTLE_INTERVAL);

        window.addEventListener('resize', this.updateContentHeight);
    }

    componentDidUpdate() {
        const { contentHeight } = this.props;

        if (contentHeight) {
            clearInterval(this.heightUpdateInterval);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateContentHeight);
    }

    shouldOpen() {
        const {
            title,
            stats,
            video,
        } = this.props;

        return (title || (stats && stats.length) || video) ? true : false;
    }

    updateContentHeight() {
        const { dispatchUpdateHeight } = this.props;
        const contentHeight = _.get(this, 'contentRef.current.clientHeight') || 0;
        dispatchUpdateHeight(contentHeight);
    }

    render() {
        const {
            title,
            stats,
            video,
            caption,
            url,
            templateKeyword,
            isOpen,
            contentHeight,
            dispatchCloseTakeover,
        } = this.props;

        if (!this.shouldOpen()) {
            return null;
        }

        const learnMoreClass = caption ? styles.learnMoreURL : '';

        return (
            <GenericTakeover
                isOpen={isOpen}
                className={styles.statsTakeover}
                containerHeight={contentHeight}
            >
                <section className={styles.takeoverWrapper}>
                    <div className={styles.logo}>
                        <Logo isHomepage={templateKeyword === 'homepage'} />
                    </div>
                    <div
                        ref={this.contentRef}
                        className={classNames(styles.takeoverContent, 'l-full-width-mobile l-standard-width')}
                    >
                        <div className={styles.takeoverStats}>
                            <ConditionalRender condition={title}>
                                <div className="type-takeover-title rte">
                                    <RawMarkup>{title}</RawMarkup>
                                </div>
                            </ConditionalRender>
                            <div className={styles.statList}>
                                {stats.map(renderStat)}
                            </div>
                        </div>
                        <div className={styles.takeoverVideo}>
                            <Asset asset={video} />
                            <ConditionalRender condition={caption || url}>
                                <div className={styles.caption}>
                                    <ConditionalRender condition={caption}>
                                        <RawMarkup>{caption}</RawMarkup>
                                    </ConditionalRender>
                                    <ConditionalRender condition={url}>
                                        <a href={url} className={classNames(learnMoreClass, 'u-font-weight-bold', 'u-uppercase', 'has-hover-state', 'white')}>
                                            {' ' /* Required for appropriate line breaking */ }
                                            Learn&nbsp;More
                                        </a>
                                    </ConditionalRender>
                                </div>
                            </ConditionalRender>
                        </div>
                    </div>
                    <button
                        className={styles.closeButton}
                        onClick={dispatchCloseTakeover}
                    >
                        <span className={styles.closeButtonFlexFix}>
                            Close <span className={classNames(styles.closeIcon, 'icon-plus')} />
                        </span>
                    </button>
                </section>
            </GenericTakeover>
        );
    }
}

HomepageTakeover.propTypes = {
    title: PropTypes.string,
    stats: PropTypes.arrayOf(PropTypes.shape({
        stat: PropTypes.string,
        description: PropTypes.string,
    })),
    video: PropTypes.shape(Asset.propTypes),
    templateKeyword: PropTypes.string,
    isOpen: PropTypes.bool,
    contentHeight: PropTypes.number,
    dispatchOpenTakeover: PropTypes.func,
    dispatchCloseTakeover: PropTypes.func,
    dispatchUpdateHeight: PropTypes.func,
    caption: PropTypes.string,
    url: PropTypes.string,
};

HomepageTakeover.defaultProps = {
    stats: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageTakeover);
