import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Asset from '../Asset/index.jsx';
import HomepageCarousel from './HomepageCarousel';
import styles from './homepage-carousel.scss';
import StatsTakeover from '../StatsTakeover';

import { statsTakeover_reduxKey } from '../StatsTakeover/constants';

const mapStateToProps = state => ({
    statsTakeoverHeight: get(state, [statsTakeover_reduxKey, 'contentHeight']),
    statsTakeoverIsOpen: get(state, [statsTakeover_reduxKey, 'isOpen']),
});

function getHeaderClasses() {
    const classes = [
        styles.homepageHeader,
    ];

    return classes.join('  ');
}

function HomepageHeader(props) {
    const {
        statsTakeoverProps,
        statsTakeoverHeight,
        statsTakeoverIsOpen,
        homepageSlides,
    } = props;

    return (
        <div
            className={getHeaderClasses()}
            style={statsTakeoverIsOpen ? { height: statsTakeoverHeight } : {}}
        >
            <StatsTakeover templateKeyword="homepage" {...statsTakeoverProps} />
            <HomepageCarousel homepageSlides={homepageSlides} />
        </div>
    );
}

HomepageHeader.propTypes = {
    asset: Asset.propTypes.asset,
    homepageSlides: PropTypes.arrayOf(PropTypes.shape({
        read_more_override: PropTypes.string,
        slide_asset: Asset.propTypes.asset,
        slide_category: PropTypes.string,
        slide_title: PropTypes.string,
        slide_url: PropTypes.string,
    })),
    statsTakeoverProps: PropTypes.shape(StatsTakeover.propTypes),
    statsTakeoverHeight: PropTypes.number,
    statsTakeoverIsOpen: PropTypes.bool,
};

export default connect(mapStateToProps)(HomepageHeader);
