import React from 'react';
import PropTypes from 'prop-types';

import styles from './trending-topics.scss';

function TrendingTopics({ topics }) {
    return (
        <div className={`${styles.topicContainer} l-full-width-mobile  u-padding-top-med  u-padding-bottom-med`}>
            <span className={`${styles.topicsLabel} dark-blue type-callout-subtitle`}>Trending:</span>
            <div className={styles.topics}>
                {topics.map((topic, idx) => (
                    <a className={`${styles.topic} type-search-result dark-gray`} href={topic.topic_url} key={idx}>
                        {topic.topic_text}
                    </a>
                ))}
            </div>
        </div>
    );
}

TrendingTopics.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape({
        tpoic_text: PropTypes.string,
        topic_url: PropTypes.string,
    })),
};

export default TrendingTopics;
