import React from 'react';
import PropTypes from 'prop-types';
import Listing from '../Listings/Listing.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import QualifiedLink from '../QualifiedLink.jsx';
import styles from './homepage-featured-insights.scss';
import { PDF_RENDER_MODE } from '../../../constants';

function Listings({
    title,
    listings,
    titleExtraClasses,
    viewAllText,
    url,
    featuredCtaIntro,
    featuredCtaText,
    featuredCtaUrl,
    renderMode,
}) {
    if (listings.length === 0) {
        return null;
    }

    const listingWrapperClasses = renderMode === PDF_RENDER_MODE ? 'u-padding-top-small l-3-columns' : 'l-3-columns';

    return (
        <React.Fragment>
            <div className={listingWrapperClasses}>
                <div className="l-two-thirds-width l-two-thirds-width--right-margin">
                    {listings.map((listingProps, idx) => (
                        <Listing
                            {...listingProps}
                            key={idx}
                            idx={idx}
                            renderMode={renderMode}
                            layoutClass="l-1m-1t-2d-column-item l-1m-1t-2d-column-item--homepage"
                        />
                    ))}
                </div>
                <div className="l-one-third-width l-one-third-width--left-margin white u-gradient-dark-blue u-gradient-angle-130">
                    <div className={`${styles.featuredCta} l-1m-1t-3d-column-item`}>
                        {featuredCtaIntro &&
                            <div className={`${styles.featuredCtaIntro} type-homepage-insight-cta u-text-center`}>Stay informed about the issues that matter to you</div>
                        }

                        { featuredCtaUrl &&
                            <a href={featuredCtaUrl} className={`${styles.ctaButton} type-callout-subtitle u-text-center`}>
                                { featuredCtaText || 'Subscribe to our latest insights' }
                            </a>
                        }
                    </div>
                </div>
            </div>
            <ConditionalRender condition={url}>
                <p className={`${styles.viewAll} type-small-caps  u-letter-spacing-1  light-blue  has-hover-state  u-uppercase  u-hide-in-print`}>
                    <QualifiedLink href={url}>
                        {viewAllText}
                    </QualifiedLink>
                </p>
            </ConditionalRender>
        </React.Fragment>
    );
}

Listings.propTypes = {
    featuredCtaIntro: PropTypes.string,
    featuredCtaText: PropTypes.string,
    featuredCtaUrl: PropTypes.string,
    title: PropTypes.string,
    listings: PropTypes.arrayOf(PropTypes.shape(Listing.propTypes)),
    titleExtraClasses: PropTypes.arrayOf(PropTypes.string),
    viewAllText: PropTypes.string,
    url: PropTypes.string,
    renderMode: PropTypes.string,
};

Listings.defaultProps = {
    title: '',
    listings: [],
    titleExtraClasses: [],
    viewAllText: 'View All Insights',
    url: '',
    renderMode: '',
};

export default Listings;
