import React from 'react';
import CookieHandlerContext from '../../context/CookieHandlerContext';

import Takeover from './layout';

function StatsTakeover(props) {
    return (
        <CookieHandlerContext.Consumer>
            {cookieHandler => <Takeover cookieHandler={cookieHandler} {...props} />}
        </CookieHandlerContext.Consumer>
    );
}

StatsTakeover.propTypes = Takeover.propTypes;

export default StatsTakeover;
