import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withRenderModeProp } from '../hocs';
import { withDefaultLayoutAndGlobals } from '../layouts/TemplateBase.jsx';
import FeaturedNews from '../elements/FeaturedNews/index.jsx';
import CommonAutocomplete from '../elements/Forms/CommonAutocomplete.jsx';
import HomepageCarousel from '../elements/HomepageCarousel/index.jsx';
import HomepageTabs from '../elements/HomepageTabs/index.jsx';
import Nav from '../elements/Nav/index.jsx';
import Listings from '../elements/Listings/index.jsx';
import HomepageFeaturedInsights from '../elements/HomepageFeaturedInsights/index.jsx';
import TrendingTopics from '../elements/TrendingTopics/';


import styles from './homepage/homepage.scss';
import { PARAM_QUERY } from '../../constants';

class Homepage extends React.Component {
    handleAutocompleteSuggestionSelected(ev, { suggestion }) {
        ev.preventDefault();

        const { window } = this.props;
        window.location.href = suggestion.url;
    }

    render() {
        const {
            autocompletePlaceholder,
            featuredNews,
            headerProps,
            homepageGridProps,
            renderMode,
            searchUrl,
            topNav,
            tabContent,
            trendingTopics,
            window,
        } = this.props;

        return (
            <React.Fragment>
                <HomepageCarousel {...headerProps} />
                <Nav {...topNav} isHomepage window={window} navStyle="solid" />

                <div className="u-overflow-x-hidden bg-white">
                    <form className="u-gradient-dark-blue  u-gradient-angle-270  u-padding-small" action={searchUrl}>
                        <CommonAutocomplete
                            placeholder={autocompletePlaceholder}
                            id="site-search"
                            name={PARAM_QUERY}
                            view="site_autocomplete"
                            inputExtraClasses={['type-search-placeholder', 'type-search-placeholder--homepage', 'dark-blue']}
                            onSuggestionSelected={(ev, { suggestion }) => this.handleAutocompleteSuggestionSelected(ev, { suggestion })}
                            variant="homepage"
                            useLargeVariant={false}
                        />
                    </form>

                    {!_.isEmpty(trendingTopics) &&
                        <TrendingTopics topics={trendingTopics} />
                    }

                    {homepageGridProps &&
                        <div className="l-standard-width">
                            <div className={`${styles.gridContainer} u-margin-bottom-large`}>
                                <HomepageFeaturedInsights
                                    listings={homepageGridProps.gridItems}
                                    viewAllText={homepageGridProps.viewAllText}
                                    url={homepageGridProps.url}
                                    renderMode={renderMode}
                                    featuredCtaIntro={homepageGridProps.featuredCtaIntro}
                                    featuredCtaTexto={homepageGridProps.featuredCtaText}
                                    featuredCtaUrl={homepageGridProps.featuredCtaUrl}
                                />
                            </div>
                        </div>
                    }

                    <div className="l-standard-width">
                        {featuredNews.gridItems &&
                            <Listings
                                listings={featuredNews.gridItems}
                                viewAllText={featuredNews.viewAllText}
                                url={featuredNews.url}
                            />
                        }
                    </div>

                    <HomepageTabs {...tabContent} window={window} />
                </div>
            </React.Fragment>
        );
    }
}

Homepage.propTypes = {
    topNav: PropTypes.shape(Nav.propTypes),
    headerProps: PropTypes.shape(HomepageCarousel.propTypes),
    homepageGridProps: PropTypes.shape(HomepageFeaturedInsights.propTypes),
    featuredNews: PropTypes.shape({
        viewAllText: PropTypes.string,
        url: PropTypes.string,
        gridItems: PropTypes.arrayOf(PropTypes.shape(_.omit(FeaturedNews.propTypes, ['window']))),
    }),
    autocompletePlaceholder: PropTypes.string,
    renderMode: PropTypes.string,
    searchUrl: PropTypes.string,
    tabContent: HomepageTabs.PropTypes,
    trendingTopics: TrendingTopics.PropTypes,
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

Homepage.defaultProps = {
    topNav: {},
    headerProps: {},
    featuredNews: [],
    autocompletePlaceholder: 'How can we help you?',
    searchUrl: '',
};

const withRenderModePropHomepage = withRenderModeProp(Homepage);
export default withDefaultLayoutAndGlobals(withRenderModePropHomepage);
